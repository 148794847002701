import React from "react";
import Img from "gatsby-image";
import AnimationLink from "../../components/animation-link";
import styled from "styled-components";
import Section from "../section";
import DarkTurquoiseArrowIcon from "../icons/dark-turquoise-arrow-icon";
import media from "../../styles/media";
import theme from "../../styles/theme";
const { colors } = theme;

const ProjectDetails = ({
  overview,
  ourSolution,
  highlightedText,
  images,
  nextPageLink,
  pageLayoutRef,
}) => {
  const nextLink = `/projects/${nextPageLink.entry.slug}`;

  return (
    <Section>
      <GridContainer>
        <Overview>
          <StyledTitle>Overview</StyledTitle>
          <StyledText>{overview.overview}</StyledText>
        </Overview>
        <OurSolution>
          <StyledTitle>Our Solution</StyledTitle>
          <StyledText>{ourSolution.ourSolution}</StyledText>
        </OurSolution>
      </GridContainer>
      <OneImageWraap>
        <Img fluid={images[0].fluid} title={images[0].title} />
      </OneImageWraap>
      <TwoColumnWrapp>
        <LeftImageWrapp>
          <ImageWrapp>
            <Img fluid={images[1].fluid} title={images[1].title} />
          </ImageWrapp>
        </LeftImageWrapp>
        <RightImageWrapp>
          <ImageWrapp>
            <Img fluid={images[2].fluid} title={images[2].title} />
          </ImageWrapp>
        </RightImageWrapp>
      </TwoColumnWrapp>
      <OneImageWraap>
        <Img fluid={images[3].fluid} title={images[3].title} />
      </OneImageWraap>
      <FlexContainer>
        <HighlightedText>
          <StyledTitle>Continued Partnership</StyledTitle>
          <StyledText>{highlightedText.highlightedText}</StyledText>
        </HighlightedText>
      </FlexContainer>
      <GridContainer>
        <WideImageWrapp>
          <Img fluid={images[0].fluid} title={images[0].title} />
        </WideImageWrapp>
      </GridContainer>

      <StyledButtonWrapp>
        <AnimationLink navLink={nextLink} pageLayoutRef={pageLayoutRef}>
          <StyledNextProject>
            Next
            <DarkTurquoiseArrowIcon />
          </StyledNextProject>
        </AnimationLink>
      </StyledButtonWrapp>
    </Section>
  );
};

export default ProjectDetails;

const GridContainer = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0px 30px;

  ${media.bigDesktop`padding-top: 80px; padding-bottom: 80px;`};
  ${media.desktop`padding-top: 75px; padding-bottom: 75px;`};
  ${media.tablet`gap: 0px 15px; padding-top: 40px; padding-bottom: 40px;`};
  ${media.thone`display: block; padding-top: 30px; padding-bottom: 30px;`};
`;

const Overview = styled.div`
  grid-column: 2 / span 5;
  ${media.desktop`grid-column: 1 / span 6;`};
  ${media.thone`padding: 30px 20px;`};
  ${media.phablet`padding-left: 0px; padding-right: 0px;`};
`;
const OurSolution = styled.div`
  grid-column: 7 / -2;
  ${media.desktop`grid-column: 7 / -1;`};
  ${media.thone`padding: 30px 20px 0 20px;`};
  ${media.phablet`padding-left: 0px; padding-right: 0px;`};
`;

const StyledTitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 2rem;
  line-height: 108.1%;
  padding-bottom: 32px;
  ${media.bigDesktop`font-size: 2rem; padding-bottom: 24px;`};
  ${media.desktop`font-size: 1.875rem; padding-bottom: 18px;`};
  ${media.tablet`font-size: 1.7rem; padding-bottom: 16px;`};
  ${media.thone`font-size: 1.5rem;`};
`;

const StyledText = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 136.6%;
  ${media.bigDesktop`font-size: 1.2rem;`};
  ${media.desktop`font-size: 1.2rem;`};
  ${media.tablet`font-size: 1rem;`};
`;

const OneImageWraap = styled.div`
  max-width: 750px;
  margin: 0 auto;
  padding-top: 100px;
  padding-bottom: 100px;
  ${media.bigDesktop`padding-top: 80px; padding-bottom: 80px; max-width: 650px;`};
  ${media.desktop`grid-column: 5 / -1; padding-top: 75px; padding-bottom: 75px; max-width: 550px;`};
  ${media.tablet`grid-column: 4 / -1; padding-top: 40px; padding-bottom: 40px; max-width: 500px;`};
  ${media.thone`padding-left: 20px; padding-right: 20px; padding-top: 30px; padding-bottom: 30px; max-width: 100%;`};
  ${media.phablet`padding-left: 0px; padding-right: 0px;`};
`;

const TwoColumnWrapp = styled.div`
  display: flex;
  padding-top: 100px;
  padding-bottom: 100px;
  justify-content: center;
  align-items: center;
  ${media.bigDesktop`padding-top: 80px; padding-bottom: 80px;`};
  ${media.desktop`padding-top: 75px; padding-bottom: 75px;`};
  ${media.tablet`padding-top: 40px; padding-bottom: 40px;`};
  ${media.thone`display: block; padding-top: 0px; padding-bottom: 0px;`};
`;

const LeftImageWrapp = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  .gatsby-image-wrapper {
    min-width: 450px;
  }

  ${media.desktop`
    .gatsby-image-wrapper {
      min-width: 400px;
    }
  `};

  ${media.tablet`
    .gatsby-image-wrapper {
      min-width: 320px;
    }
  `};
  ${media.thone`
    padding-top: 30px;
    padding-bottom: 30px;
    .gatsby-image-wrapper {
      min-width: 100%;
    }
  `};
`;

const RightImageWrapp = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  .gatsby-image-wrapper {
    min-width: 350px;
  }
  ${media.desktop`
    .gatsby-image-wrapper {
      min-width: 280px;
    }
  `};
  ${media.tablet`
    .gatsby-image-wrapper {
      min-width: 200px;
    }
  `};
  ${media.thone`
    padding-top: 30px;
    padding-bottom: 30px;
    .gatsby-image-wrapper {
      min-width: 100%;
    }
  `};
`;

const ImageWrapp = styled.div`
  display: block;
  ${media.thone`padding-left: 20px; padding-right: 20px; width: 100%;`};
  ${media.phablet`padding-left: 0px; padding-right: 0px;`};
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  ${media.bigDesktop`padding-top: 80px; padding-bottom: 80px;`};
  ${media.desktop`padding-top: 75px; padding-bottom: 75px;`};
  ${media.tablet`padding-top: 40px; padding-bottom: 40px;`};
  ${media.thone`padding: 30px 20px;`};
  ${media.phablet`padding-left: 0px; padding-right: 0px;`};
`;

const HighlightedText = styled.div`
  max-width: 484px;
  text-align: center;
  ${media.thone`max-width: 100%;`};
`;

const WideImageWrapp = styled.div`
  grid-column: 2 / -2;
  ${media.tablet`grid-column: 1 / -1;`};
  ${media.thone`padding-left: 20px; padding-right: 20px; width: 100%;`};
  ${media.phablet`padding-left: 0px; padding-right: 0px;`};
`;

const StyledButtonWrapp = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 48px;
`;

const StyledNextProject = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 2rem;
  line-height: 108.1%;
  color: ${colors.darkTurquoise};
  display: flex;
  align-items: center;
  margin-top: 32px;
  svg {
    margin-left: 30px;
    margin-right: 12px;
    ${media.desktop`margin-left: 12px; height: 30px;`};
    ${media.tablet`margin-left: 8px; height: 24px;`};
  }

  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.41, 0, 0.019, 1);
  &:hover {
    svg {
      transform: translateX(10px);
      transition-duration: 0.3s;
    }
  }
  ${media.bigDesktop`font-size: 1.8rem;`};
  ${media.desktop`font-size: 1.5rem;`};
  ${media.tablet`font-size: 1.3rem;`};
  ${media.thone` float: right; margin-bottom: 0px; margin-top: 0px;`};
`;
