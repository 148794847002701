import React from "react";
import AnimationLink from "../../components/animation-link";
import styled from "styled-components";
import Section from "../../components/section";
import DownIcon from "../../components/icons/down-icon";
import media from "../../styles/media";

const ProjectHero = (props) => {
  const {
    title,
    client,
    output,
    publishDate,
    externalLink,
    coverSectionUnique,
    pageLayoutRef,
  } = props;

  const onClick = () => {
    const elmnt = document?.getElementById(coverSectionUnique);
    if (elmnt)
      elmnt.scrollIntoView({
        behavior: "smooth",
      });
  };

  return (
    <Layout>
      <Section>
        <AnimationLink navLink={`/`} pageLayoutRef={pageLayoutRef}>
          <MenuLogo>
            <LogoText>belo.</LogoText>
          </MenuLogo>
        </AnimationLink>
        <StyledContainer>
          <StyledGridWrapp>
            {title && (
              <StyledWrappTitle>
                <StyledTitle>{title}</StyledTitle>
              </StyledWrappTitle>
            )}
            <StyledProjectDetails>
              {client && (
                <>
                  <StyledText>Client</StyledText>
                  <StyledValue>{client}</StyledValue>
                </>
              )}
              {output && (
                <>
                  <StyledText>Output</StyledText>
                  <StyledValue>{output}</StyledValue>
                </>
              )}
              {publishDate && (
                <>
                  <StyledText>Year</StyledText>
                  <StyledValue>{publishDate.substring(0, 4)}</StyledValue>
                </>
              )}
              {externalLink && (
                <>
                  <StyledTextLink href={externalLink}>View live</StyledTextLink>
                </>
              )}
            </StyledProjectDetails>
          </StyledGridWrapp>
        </StyledContainer>
      </Section>
      <StyledDownIconWrapp>
        <div onClick={onClick}>
          <DownIcon />
        </div>
      </StyledDownIconWrapp>
    </Layout>
  );
};

export default ProjectHero;

const Layout = styled.div`
  position: relative;
`;

const MenuLogo = styled.div`
  position: relative;
  top: 40px;
  left: 0px;
  width: 99px;
  height: 39px;
  z-index: 999;
`;

const LogoText = styled.div`
  letter-spacing: -0.5px;
  font-size: 2.7rem;
`;

const StyledContainer = styled.div`
  width: 100%;
  padding-bottom: 12px;
`;

const StyledGridWrapp = styled.div`
  margin-top: 200px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0px 30px;
  ${media.bigDesktop`margin-top: 150px;`};
  ${media.desktop`margin-top: 120px; `};
  ${media.tablet`margin-top: 87px; gap: 0px 15px;`};
  ${media.thone`display: block;`};
`;

const StyledWrappTitle = styled.div`
  grid-column: 2 / span 8;
  ${media.tablet`grid-column: 1 / span 8;`};
  ${media.thone`display: block; padding-bottom: 32px;`};
`;

const StyledTitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 5rem;
  line-height: 108.1%;
  ${media.bigDesktop`font-size: 4.5rem;`};
  ${media.desktop`font-size: 4rem; `};
  ${media.tablet`font-size: 3.5rem;`};
  ${media.thone`font-size: 3rem; text-align: center;`};
  ${media.phone`font-size: 3rem;`};
`;

const StyledProjectDetails = styled.div`
  padding-top: 18px;
  grid-column: 10 / -1;
  ${media.tablet`grid-column: 9 / -1;`};
  ${media.thone`
    width: max-content;
    float: right;
    margin-right: 40px;
    padding-top: 0px;
  `};
  ${media.phablet`margin-right: 20px;`};
  ${media.phone`margin-right: 0px;`};
`;

const StyledText = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 91.6%;
  color: #818181;
  padding-top: 5px;
  padding-bottom: 5px;
`;

const StyledValue = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.3rem;
  line-height: 91.6%;
  padding-bottom: 8px;
`;

const StyledTextLink = styled.a`
  font-style: normal;
  font-weight: 300;
  font-size: 1.3rem;
  line-height: 91.6%;
  margin-top: 12px;
  border-bottom: 1px solid;
  padding-bottom: 6px;
`;

const StyledDownIconWrapp = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  div {
    cursor: pointer;
    animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    -moz-animation: bounce 2s infinite;
    -o-animation: bounce 2s infinite;

    @-webkit-keyframes bounce {
      0%,
      20%,
      50%,
      80%,
      100% {
        -webkit-transform: translateY(0);
      }
      40% {
        -webkit-transform: translateY(-30px);
      }
      60% {
        -webkit-transform: translateY(-15px);
      }
    }

    @-moz-keyframes bounce {
      0%,
      20%,
      50%,
      80%,
      100% {
        -moz-transform: translateY(0);
      }
      40% {
        -moz-transform: translateY(-30px);
      }
      60% {
        -moz-transform: translateY(-15px);
      }
    }

    @-o-keyframes bounce {
      0%,
      20%,
      50%,
      80%,
      100% {
        -o-transform: translateY(0);
      }
      40% {
        -o-transform: translateY(-30px);
      }
      60% {
        -o-transform: translateY(-15px);
      }
    }
    @keyframes bounce {
      0%,
      20%,
      50%,
      80%,
      100% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(-30px);
      }
      60% {
        transform: translateY(-15px);
      }
    }
  }
`;
