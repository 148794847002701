import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import Section from "../section";
import media from "../../styles/media";

const CoverSection = ({ coverImage, coverSectionUnique }) => {
  return (
    <Section>
      <StyledContainer id={coverSectionUnique}>
        <ImageWraap>
          <Img fluid={coverImage.fluid} title={coverImage.title} />
        </ImageWraap>
      </StyledContainer>
    </Section>
  );
};

export default CoverSection;

const StyledContainer = styled.div`
  padding-top: 100px;
  padding-bottom: 30px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0px 30px;

  ${media.bigDesktop`padding-top: 80px;`};
  ${media.desktop`padding-top: 75px;`};
  ${media.tablet`gap: 0px 15px; padding-top: 60px;`};
  ${media.thone`display: block; padding-top: 50px;`};
`;

const ImageWraap = styled.div`
  grid-column: 5 / -2;
  ${media.desktop`grid-column: 5 / -1;`};
  ${media.tablet`grid-column: 4 / -1;`};
  ${media.thone`padding-left: 20px; padding-right: 20px;`};
  ${media.phablet`padding-left: 0px; padding-right: 0px;`};
`;
