import React, { useRef } from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";
import Layout from "../components/layout";
import ProjectHero from "../modules/hero/project-hero";
import ModuleFooter from "../modules/module-footer";
import CoverSection from "../components/project/cover-section";
import ProjectDetails from "../components/project/project-details";

const ProjectTemplate = ({ location, data }) => {
  const {
    projectData: {
      slug,
      seo,
      contentful_id,
      title,
      client,
      externalLink,
      output,
      publishDate,
      overview,
      ourSolution,
      highlightedText,
      illustrations,
      nextPageLink,
      footer,
    },
  } = data;
  const pageLayoutRef = useRef();
  const coverSectionUnique = `${slug}-${contentful_id}`;

  return (
    <div ref={pageLayoutRef}>
      <SEO data={seo} location={location}/>
      <Layout location={location}>
        <ProjectHero
          title={title}
          client={client}
          output={output}
          publishDate={publishDate}
          externalLink={externalLink}
          coverSectionUnique={coverSectionUnique}
          pageLayoutRef={pageLayoutRef}
        />
        <CoverSection
          coverImage={illustrations[0]}
          coverSectionUnique={coverSectionUnique}
        />
        <ProjectDetails
          overview={overview}
          ourSolution={ourSolution}
          highlightedText={highlightedText}
          images={illustrations.slice(1)}
          nextPageLink={nextPageLink}
          pageLayoutRef={pageLayoutRef}
        />
        <ModuleFooter
          {...footer}
          location={location}
          pageLayoutRef={pageLayoutRef}
        />
      </Layout>
    </div>
  );
};

export default ProjectTemplate;

export const ProjectTemplateQuery = graphql`
  query($contentfulId: String!) {
    projectData: contentfulModuleProject(contentful_id: { eq: $contentfulId }) {
      name
      slug
      contentful_id
      seo {
        ...GlobalSEOFragment
      }
      title
      client
      externalLink
      output
      publishDate
      overview {
        overview
      }
      ourSolution {
        ourSolution
      }
      highlightedText {
        highlightedText
      }
      illustrations {
        title
        fluid(
          maxWidth: 800
          quality: 95
          toFormat: JPG
          cropFocus: FACES
          resizingBehavior: FILL
        ) {
          ...GatsbyContentfulFluid
        }
      }
      nextPageLink {
        ...GlobalLinkFragment
      }
      footer {
        ...ModuleFooterFragment
      }
    }
  }
`;
