import React from "react";

const DownIcon = () => {
  return (
    <svg
      width="38"
      height="75"
      viewBox="0 0 38 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.3945 55.7119L19.0348 74.0716L0.675106 55.7119"
        stroke="black"
        strokeWidth="1.26619"
      />
      <path
        d="M19.0352 74.0288L19.0352 0"
        stroke="black"
        strokeWidth="1.45956"
      />
    </svg>
  );
};

export default DownIcon;
